export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Block Blast Game",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://games.crazygames.com/en_US/block-blast-3d/index.html?v=1.305",
    domain: "block-blast.fun",
    gaId: "G-R7HW4801T3",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
